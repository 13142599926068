import React from "react";
import { Helmet } from "react-helmet";
import styles from "./Contact.module.css";
import { Header} from '../../img'

export default () => {

  return (
    <div className={`container, ${styles.screen}`}>
      <Helmet>
        <title>Contact</title>
        <meta name="description" content="Contact us today"></meta>
      </Helmet>

     

      <div className="row justify-content-center">
  
      <div className="shadow-lg col-md-6 col-xs-12" style={{padding: 0, marginTop: 10}}>
      <img   src={Header} className={`img-responsive ${styles.header}`} alt="stutz concrete contact infomation"/>
    </div>
    
  </div>
  <div className="row justify-content-center" style={{textAlign: 'center'}}>
        <div className="col-md-12 ">
          <h1>Josh M. Stutz</h1>
          <h3 style={{marginTop: "10px"}}>Owner</h3>
          
        </div>
      </div>
     
      <div className="row justify-content-center" >
        <div className="col-md-4 col-xs-12">
          <div className="col-md-6 col-xs-6" style={{textAlign: 'right', padding: "10px"}} >
            <h5>509.304.8249</h5>
            <h5>Bonded & Insured</h5>
            <h5>P.O. Box 1044</h5>
          
          </div>
      
          <div className="col-md-6 col-xs-6 " style={{textAlign: 'left', padding: "10px"}}>
            <h5>Stutzconcrete@yahoo</h5>
            <h5>Lic# STUTZC*825DZ</h5>
            <h5>Roslyn, Wa</h5>
          
          </div>
        </div>
      </div>
  
    </div>
  );
 
};
