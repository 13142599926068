import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import styles from "./Landing.module.css";
//import {Logo,   CleanFoundation, CrewShot,  WinterFoundation} from '../../img'
  import {Header,  Job2, Job3,  CleanFoundation, CrewShot, GolfCourse, LittleGarage, LongReach,  WhiteFoundation, WinterFoundation} from '../../img'

function Landing() {

 const colageStyle = {
     padding: 1
   }

 

  return (
    <div className={`container ${styles.component}`}>
      <Helmet>
        <title>Stutz Concrete</title>
        <meta name="description" content="Built to last"></meta>
      </Helmet>
     
      <div className={`row justify-content-center ${styles.header} `}>
        <div className="col-md-12 ">
          {/* <h1>Stutz Concrete</h1> */}
 
          
        </div>
      
        <div className={`col-lg-12 ${styles.darkoverlay}`}>
          <h3>   Serving all Kittitas County.</h3>
          <p className="">Quality foundations, Retaining walls, custom form work & more. License, bonded and insured.</p>
        </div>

         <div className="col-md-12  justify-content-center"style={{padding: 0}}>
          <img className=" page-header w-100" src={Header} alt="Stutz Concrete logo" style={{marginTop: 10}} />
         </div>

  
        <div className="card shadow-lg col-lg-12" style={{padding: 0}}>
          <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
            <ol className="carousel-indicators">
              <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
              <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
              <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
              
            </ol>
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <img className="d-block w-100  " src={WinterFoundation} alt="First slide"/>
                  </div>
                  <div className="carousel-item">
                    <img className="d-block w-100 " src={CleanFoundation} alt="Second slide"/>
                  </div>
                  <div className="carousel-item">
                    <img className="d-block w-100" src={CrewShot} alt="Third slide"/>
                  </div>
                </div>
              <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="sr-only">Previous</span>
              </a>
              <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="sr-only">Next</span>
              </a>
            </div>
          </div>
      </div>
 
          {/* ============================== */}
          <div className={`row justify-content-center ${styles.header} `}>
        <div className="col-lg-12" style={{margin: 20}}>
         
        </div>
      </div>

    
      <div className="row justify-content-center"> 
        <div className="card shadow-lg col-xs-12 col-md-12" style={{padding: 0}} >
          <div className="card">
            
          <img   src={Job3} className={` img-responsive`} alt="Stutz Concrete work"/>
          </div>

          <div style={{display: 'flex', flexDirection: 'row'}}>
           {/**Bottom row  */}
            <div className=" card "  >
            <img   src={WhiteFoundation} className={` img-responsive `} alt="Stutz Concrete work"/>
            </div>
            <div className="card ">
            <img   src={Job2} className={` img-responsive`} alt="Stutz Concrete work"/>
            </div>
          </div>

          <div style={{display: 'flex', flexDirection: 'row'}}>
           {/**Bottom row  */}
            <div className=" col-md-4 " style={colageStyle} >
            <img   src={GolfCourse} className={`img-responsive `} alt="Stutz Concrete work"/>
            </div>
            <div className="col-md-4" style={colageStyle} >
            <img   src={LittleGarage} className={`img-responsive`} alt="Stutz Concrete work"/>
            </div>
            <div className="col-md-4" style={colageStyle} >
            <img   src={LongReach} className={`img-responsive`} alt="Stutz Concrete work"/>
            </div>
          </div>

        </div>
      </div>
  
    
  
      <div className="row justify-content-center" style={{margin: 10}}>
        <div className={` col-lg-12 ${styles.darkoverlay}`}>
          <Link to="/contact">
            <button type="button" className="btn btn-danger">Contact today</button>
          </Link>
        </div>
      </div>
    
   </div>
  );
}

export default Landing;
