import React, { Component } from "react";
import { Link } from "react-router-dom";
// import styles from './NavBar.module.css'


class NavBar extends Component {


  render() {
   
    return (

   
      /**++++++++++++++++++++++ */

      <nav className={" navbar-light "}  >
        <div className="container ">
       
          {/* <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#mobile-nav"
          >
          
            <span className="navbar-toggler-icon" />
          </button> */}
          {/** */}
          <div className="row" style={{}}>
            <ul
              className="nav navbar-nav mr-auto  "
              style={{  paddingRight: "2.5rem", display: 'flex', flexDirection: 'row' }}
            >
              <li>
              <Link className="navbar-brand" to="/">
            {"Stutz Concrete"}
          </Link>
              </li>
              {/* <li className="nav-item" style={{ paddingRight: "25px" }}>
                <Link className="nav-link" to="/work">
                  work
                </Link>
              </li> */}

              <li className="nav-item">
                <Link className="nav-link " to="/contact" style={{color: 'red'}}>
                  Contact today!
                </Link>
              </li>
              {/* <li className="nav-item">
                <Link className="nav-link " to="/pixelandprocessor">
                  pixel & processor
                </Link>
              </li> */}

              {/** 
              <li className="nav-item">
                <Link className="nav-link" to="/portfolio">
                  Portfolio
                </Link>
              </li>
            
              <li className="nav-item">
                <Link className="nav-link" to="/thoughts">
                  Thoughts
                </Link>
              </li>
              */}
            </ul>
          </div>
        </div>
      </nav>
    );

  }



}

export default NavBar;
