import React from "react";

export default () => {
  return (
    <div className="col">
      <span className="text-center text-dark">
  
     
        <a href="https://www.facebook.com/Stutz-Concrete-926309214218793/about/?ref=page_internal">
          <i className="fab fa-facebook " />
          Facebook
        </a>{" "}
      
      </span>
    </div>
  );
};
